import React, { useEffect, useState } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import DOMPurify from 'isomorphic-dompurify';
import getConfig from 'next/config';
import PropTypes from 'prop-types';

import GET_LAYOUT_CONFIG from '../Layouts/graphql/getLayoutConfig';
import LOG_OUT from '../Layouts/graphql/logOut.gql';
import getFetchPolicyWithExpiry from '../../lib/getFetchPolicyWithExpiry';
import FetchPolicy from '../../constants/FetchPolicy';

import { LoadingSpinner } from '../ui/Loading';
import {
  Body, Button, Contact, Logo, Wrapper,
} from './ErrorPage.style';
import fontFace from '../../styles/libs/fontFace';
import { getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

import logToCasClientSide, { AuditType } from '../../lib/logToCasClientSide';
import { SENTRY_ORIGINS, captureError } from '../../lib/sentry';

const propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  layout: PropTypes.shape({
    header: PropTypes.shape({
      headerConfiguration: PropTypes.shape({
        logoUrl: PropTypes.string,
      }),
    }),
  }),
  layoutError: PropTypes.shape(IbePropTypes.apolloError),
  layoutLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({
    message: PropTypes.string,
  }),
  logOut: PropTypes.func.isRequired,
};

const defaultProps = {
  layout: undefined,
  layoutError: undefined,
  err: {},
};

const ErrorPage = ({
  client, layout, layoutError, layoutLoading, err, logOut,
}) => {
  const { publicRuntimeConfig } = getConfig();
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
      // As this is a component, to track error rates
      // we need to push an event when we're here
      window.dataLayer.push({
        event: 'page_view',
        page_title: 'Error Page',
        page_location: '/error',
        page_referrer: window.location.pathname,
      });
    }

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.innerHTML = `
    ${fontFace({ name: 'Elliot', src: 'FSElliotWeb-Regular' })}
    ${fontFace({ name: 'ElliotBold', src: 'FSElliotWeb-Bold', fontWeight: 'bold' })}
    `;
    head.appendChild(style);

    // Log error to CAS
    logToCasClientSide(AuditType.Error, 'Error Page', 'Error Page', err);

    // And log to Sentry
    if (err) {
      captureError(err, SENTRY_ORIGINS.ERROR_PAGE);
    }

    return () => {
      head.removeChild(style);
    };
  }, []);

  const handleButtonClick = async () => {
    setReloading(true);

    await client.resetStore().then(() => {
      logOut({
        variables: {
          input: {},
        },
      }).then(() => {
        window.sessionStorage.clear();
        window.localStorage.clear();
        caches.keys().then(keys => {
          keys.forEach(key => caches.delete(key));
        });

        const { ReloadCTAActionUrl } = publicRuntimeConfig.ENVIRONMENT;
        if (ReloadCTAActionUrl) {
          window.location.href =
            publicRuntimeConfig.ENVIRONMENT.ReloadCTAActionUrl;
        }
      });
    });
  };

  const headerText = getDictionaryItem(client, 'ErrorPage__Header__Title');
  const bodyText = getDictionaryItem(client, 'ErrorPage__Body__RichText');
  const buttonText = getDictionaryItem(client, 'ErrorPage__Reload__Button');
  const contactText = getDictionaryItem(client, 'ErrorPage__Contact__RichText');

  return (
    <Wrapper>
      {!layoutError && !layoutLoading && layout && (
        <Logo
          src={layout.header.headerConfiguration.logoUrl}
          alt="The Caravan Club"
        />
      )}
      {!reloading && (
        <>
          <Body>
            <h1>{headerText || 'An error has occurred'}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bodyText) }}
            />
          </Body>
          <Button onClick={() => handleButtonClick()}>
            {buttonText || 'Reload'}
          </Button>
          <Contact
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(contactText),
            }}
          />
        </>
      )}
      {reloading && (
        <Body><LoadingSpinner /></Body>
      )}
    </Wrapper>
  );
};

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default compose(
  withApollo,
  graphql(GET_LAYOUT_CONFIG, {
    options: () => {
      const fetchPolicy = getFetchPolicyWithExpiry('headerAndFooter', {
        defaultPolicy: FetchPolicy.CACHE_FIRST,
        expiry: 1000 * 60 * 5, // 5 minutes
        expiryPolicy: FetchPolicy.NETWORK_ONLY,
      });

      return { fetchPolicy };
    },
    props: ({ data }) => ({
      layout: data.layout,
      layoutError: data.error,
      layoutLoading: data.loading,
    }),
  }),
  graphql(LOG_OUT, {
    props: ({ mutate }) => ({
      logOut: () => mutate({ variables: { input: {} } }),
    }),
  }),
)(ErrorPage);
