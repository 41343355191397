import React from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';

import withData from '../lib/withData';
import ErrorPage from '../components/ErrorPage';

const Error = ({ err }) => <ErrorPage err={err} />;

export default compose(withData)(Error);

Error.propTypes = {
  err: PropTypes.shape({
    message: PropTypes.string,
  }),
};

Error.defaultProps = {
  err: {},
};

Error.getInitialProps = ({ err }) => ({ err });
