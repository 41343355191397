import styled from 'styled-components';
import theme from '../../styles/config/theme';
import { below } from '../../styles/libs';

export const Wrapper = styled.div`
  max-width: 1024px;
  margin: 6rem auto 0 auto;
  font-family: 'Elliot', sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 0 6rem;

  ${below(theme.DESKTOP)`
    margin-top: 4rem
    padding: 0 2rem
  `}

  h1 {
    font-family: 'ElliotBold', sans-serif;
    font-size: 40px;
    line-height: 52px;
  }

  p {
    margin: 16px 0;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const Logo = styled.img`
  width: 288px;
  height: 68px;
`;

export const Body = styled.div`
  margin: 4rem 0;

  ${below(theme.DESKTOP)`
    margin-top: 3rem;
  `}
`;

export const Button = styled.button`
  padding: 0 20px;
  height: 50px;
  font-family: 'Elliot', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: ${theme.COLOR_WHITE};
  background-color: ${theme.COLOR_AMBER_RED};
  border: 0;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: ${theme.COLOR_AMBER_RED_DARK};
  }

  ${below(theme.DESKTOP)`
    margin-bottom: 3rem;
  `}
`;

export const Contact = styled.div`
  margin-top: 1rem;
`;
